@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

.App {
  background-color: #ededed;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0!important;
  font-family: 'Nunito Sans', sans-serif!important;
  overflow: auto!important;
}

:root {
  --color-primary: #ededed;
  --color-primary-orange: #e78022;
  --color-primary-blue: #093055;
  --color-secondary-blue: #1c6d9c;
  --color-button-background: #e78022;

  --color-grey-light-1: #black;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
 
  --color-primary-light: #1c6d9c;
  --color-primary-light-rgda: rgb(28, 109, 156, 0.6);
  --color-primary-dark: #ba265d;
  --new-ui-primary : #2591CE;

  --color-secondary: #e78021;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-5: #fbfbfb;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-white: #fff;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --linear-gradient: linear-gradient(to right, #1974b5, #13599a);

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
  background: var(--color-primary);
}

.greenborder {
  border: 2px solid green;
  border-radius: 5px;
}
.redboreder {
  border: 2px solid red;
  border-radius: 5px;
}
.normalborder {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.highlightborder {
  border: 1px solid rgb(4, 20, 233);
  border-radius: 5px;
}
.swal2-container {
  z-index: 100000;
}
img.displayed {
  max-width: 200px;
}
.loadingdiv {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // animation-name: doAnimStay;
  // animation-duration: 5s;
  // animation-fill-mode: forwards;
}
.swal2-container {
  z-index: 10000 !important;
}
// @keyframes doAnimStay{
//   0%   {opacity:0;}
//   100% {opacity:1;}
// }
.errduration .MuiFormHelperText-root {
  width: 300px !important;
  position: absolute !important;
  top: 94% !important;
  color: red !important;
}
@font-face {
  font-family: "myfontbold";
  src: url("../src/font/NunitoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "myfontregular";
  src: url("../src/font/NunitoSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "myfontblitalic";
  src: url("../src/font/NewsGothBTBoldItalic.ttf") format("truetype");
}

.MuiTab-root.Mui-selected {
  background-color: white!important;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
}

.MuiButtonBase-root {
  text-transform: capitalize!important;
  font-size: 1rem!important;
  padding: 12px 16px;
  font-family: "Nunito Sans", sans-serif!important;
}
.MuiTableHead-root > tr > th {
  color: white!important;
  background-color: var(--color-primary);
}
.MuiTableCell-root {
  padding: 8px!important;
}
.gamification_lineHeader__2p4c_{
  width: 14rem;
}
.MuiTableCell-body {
  font-family: 'Nunito Sans', sans-serif!important;
}
.MuiTableCell-head {
  font-family: 'Nunito Sans', sans-serif!important;
  font-weight: 800!important;
}