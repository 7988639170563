.body{
  background-color: rgb(196, 190, 190);
}
.navbar {
  background-color: #217fb5;
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
}

.card{
  background-color: whitesmoke;
  border-radius: 5px;
  box-shadow: 0px 2px 14px -1px rgba(39, 37, 37, 0.1), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 290px 0px rgb(0 0 0 / 2%);
  width: 75%;
  height: auto;
  margin-left: auto; 
  margin-right: auto;
  margin-top: 1.5rem;
}
.sub-head{
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: left;
  border-bottom:1px solid rgb(50, 47, 47);
}
.sub-head > h2{
  padding-left: 1rem;
}
.forms{
 display: flex;
 flex-direction:column;
 align-items: center;
 width:100%;
 padding:10px
}
.dev{
  width: 100%;
  display: flex;
}
.org-name{
  width: 88%;
  margin: 10px;
  height: 42px;
  border: 1px solid rgb(74, 72, 72);
  border-radius: 5px;
  font-size: 17px;
  color:rgb(46, 39, 39)
}
.lables{
  width:9rem;
}
.swal-list{
  list-style: none;
  text-align: left;
  margin: 0 0 0 3rem;
}
.button {
  border: 1px solid #217fb5 !important;
  margin-bottom: 20px !important;
  transition: background-color 0.3s !important;
  margin-top: 1.5rem !important;
}

.button:hover {
  background-color: #2980b9 !important;
  color: white !important;
  font-weight: bold !important;
}
.subnote{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #217fb5;
  border-radius: 5px;
  text-align: center;
  background-color: #e7f3fe;
  border-left: 6px solid #217FB5;
}
.buttonscontainer{
  background-color:  whitesmoke;
  border-radius: 5px;
  box-shadow: 0px 2px 14px -1px rgba(39, 37, 37, 0.1), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 290px 0px rgb(0 0 0 / 2%);
  width: 75%;
  height: 15rem;
  margin-left: auto; 
  margin-right: auto;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buttons{
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}
.dellables{
    width:3rem;
}
.swal2-html-container{
  font-size: 1em;
}

